<template>
	<v-container fluid>
		<v-row class="primary sticky-top">
			<v-col cols="12">
				<v-toolbar flat color="primary" dark>
					<v-toolbar-title>{{ $t('SmartNavBar.My_invoices') }}</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-autocomplete :items="invoiceIds" v-model="invoiceId" class="my-auto"
							:placeholder="$t('Invoice_number')" flat dense solo light hide-details clearable
							@change="onSearch"></v-autocomplete>
					</v-toolbar-items>
				</v-toolbar>
			</v-col>
			<v-progress-linear v-if="loading" bottom indeterminate striped color="white"></v-progress-linear>
		</v-row>

		<v-row justify="center" v-for="invoice of invoiceList" :key="invoice.RowIdentIdx">
			<v-col cols="12" md="9" lg="7">
				<InvoiceCard :faktuurh="invoice" />
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-btn class="my-3" v-if="this.rowIdent" :loading="loading" color="primary" @click="getInvoiceList()">
				<span class="mx-4">{{ $t('Load_more') }}</span>
			</v-btn>
		</v-row>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import InvoiceCard from "../components/InvoiceCard";
export default {
	name: "SalesInvoices",
	components: { InvoiceCard },

	data() {
		return {
			loading: false,
			tabId: 0,
			invoiceList: [],
			rowsToBatch: 10,
			rowNum: 0,
			rowIdent: "",
			invoiceId: 0,
			invoiceIds: []
		};
	},

	created() {
		caas
			.rpc("getPortalUserInvoiceIds", {})
			.then((response) => {
				this.invoiceIds = response.data.success.invoiceIds
			})
			.catch((error) => {
				console.log(error.message);
				this.loading = false
			})
	},

	methods: {
		getInvoiceList() {
			this.loading = true;
			let params = {
				year: 0,
				invoiceId: this.invoiceId || 0,
				rowNum: this.rowNum,
				rowIdent: this.rowIdent,
				rowsToBatch: this.rowsToBatch,
			};
			caas
				.rpc("getPortalUserInvoices", params)
				.then((response) => {
					if (response.data.success.data) {
						this.invoiceList.push(
							...response.data.success.data.invoiceList.invoice
						);
						this.rowNum = response.data.success.rowNum;
						this.rowIdent = response.data.success.rowIdent;
						this.loading = false
					} else if (response.data.success.error) {
						throw response.data.success.error;
					}
				})
				.catch((error) => {
					console.log(error.message);
					this.loading = false
				})
		},
		onClear() {
			this.invoiceId = 0;
			this.onSearch();
		},
		onSearch() {
			this.invoiceList = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.getInvoiceList();
		},
	},
	mounted() {
		this.getInvoiceList();
	},
};
</script>
