<style scoped>
.v-text-field>>>.v-input__slot::before {
	border-color: white !important;
}

.v-text-field>>>.v-input__slot {
	font-size: 14px !important;
}
</style>
<template>
	<v-card outlined rounded>
		<v-card-title>
			<span>{{ $t('InvoiceCard.Factuur') }} {{ faktuurh.faktuurnr }}</span>
			<v-spacer /> <v-btn plain @click.stop="previewDocument">
				<v-icon>mdi-file-document-outline</v-icon>
				<span>{{ $t('InvoiceCard.pdf') }}</span>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-row justify="start">
				<v-col cols="auto">
					<v-text-field :label="$t('InvoiceCard.Datum')" :value="$d(new Date(faktuurh.datum), 'short')"
						readonly />
				</v-col>
				<v-col cols="auto">
					<v-text-field readonly :label="$t('InvoiceCard.Totaal')"
						:value="$n(faktuurh.totaal, 'decimal') + ' ' + faktuurh.muntcode" />
				</v-col>
				<v-col cols="auto">
					<v-text-field :label="$t('InvoiceCard.Vervaldatum')"
						:value="$d(new Date(faktuurh.vervaldatum), 'short')" readonly />
				</v-col>
			</v-row>

			<v-sheet outlined>
				<v-data-table disable-sort disable-pagination hide-default-footer :items="faktuurh.invoiceLine"
					:headers="headers" dense height="175" fixed-header>
					<template v-slot:[`item.ehp`]="{ item }">
						<span>{{ $n(item.ehp, "decimal") }}</span>
					</template>
					<template v-slot:[`item.hkort`]="{ item }">
						<span v-if="item.hkort">{{
							$n(item.hkort * -0.01, "percent")
						}}</span>
					</template>
				</v-data-table>
			</v-sheet>

		</v-card-text>

	</v-card>
</template>

<script>
import caas from "@/services/caas";
export default {
	name: "InvoiceCard",
	props: {
		faktuurh: Object,
	},
	data() {
		return {
			headers: [
				{ text: this.$t("InvoiceCard.Code"), value: "anr" },
				{
					text: this.$t("InvoiceCard.Omschrijving"),
					value: "omschrijving",
					align: "start",
					width: "40%",
				},
				{ text: this.$t("InvoiceCard.Aantal"), value: "aantal", align: "end" },
				{ text: this.$t("InvoiceCard.Eenh_prijs"), value: "ehp", align: "end" },
				{ text: "", value: "hkort", align: "end" },
				{ text: this.$t("InvoiceCard.BTW"), value: "btw", align: "end" },
				{ text: this.$t("InvoiceCard.Ref"), value: "document" },
			],
		};
	},
	computed: {},
	methods: {
		previewDocument() {
			let params = {
				invoiceId: this.faktuurh.faktuurnr,
				fiscalYear: this.faktuurh.jaar,
			};

			caas.rpc("getSalesInvoiceUrl", params).then((response) => {
				window.open(response.data.success.invoiceUrl);
			});
		},
	},
};
</script>

